import {styled} from '@mui/material';
import {memo} from 'react';

const DefaultCatchPhraseBox = styled('div')(({theme, sx}) => ({
    padding: '48px 64px',
    backgroundColor: theme.palette.text.dark,
    [theme.breakpoints.down('md')]: {
        padding: '24px',
    },
    ...sx,
}));

export default memo(DefaultCatchPhraseBox);
